import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useGetCourse } from "../../hooks/useGetCourse";
import { useGetQuizzesByUser } from "../../hooks/useGetQuizzesByUser";
import { set } from "date-fns";
import NewReleasesIcon from '@mui/icons-material/NewReleases';


const mockTopics = [
  {
    topic: "Topic 1",
    subtopics: ["Subtopic 1", "Subtopic 2", "Subtopic 3"],
  },
  {
    topic: "Topic 2",
    subtopics: ["Subtopic 4", "Subtopic 5", "Subtopic 6"],
  },
];



export const StartQuizModal = ({ open, onClose, onStartQuizClick,  course , createdNewThread, setCreatedNewThread, theme  }) => {
  const [quizMode, setQuizMode] = useState("practice");
  const [numQuestions, setNumQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState("easy");
  const [topics,setTopics] = useState(mockTopics);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [expandedTopic, setExpandedTopic] = useState(null);
  const [isAddTopicOpen, setIsAddTopicOpen] = useState(false);
  const [newTopic, setNewTopic] = useState("");
  const { getCourse } = useGetCourse();
  const [courseData, setCourseData] = useState(null);

  
 const {
    mutate: getQuizzes,
    isLoading: isQuizzesLoading,
    isError: isQuizzesError,
    data: quizzesData,
  } = useGetQuizzesByUser();

  function convertTopics(inputData) { return inputData.map(item => { const [topic, subtopics] = Object.entries(item)[0]; return { topic, subtopics }; }); }

  useEffect(() => {

    if(open){
      
    const fetchCourse = async () => {
      const data = await getCourse(course);
      setCourseData(data);
    };
    fetchCourse();
    }

  }, [open]);

  useEffect(() => {
    if (courseData?.data?.response?.topics) {
      const mappedTopics = convertTopics(courseData.data.response.topics);
      setTopics(mappedTopics);
    }
  }, [courseData]);



  
  const handleToggleTopic = (topic, hasSubtopics) => {
    if (!hasSubtopics) {
      setSelectedSubtopics((prev) =>
        prev.includes(topic)
          ? prev.filter((t) => t !== topic)
          : [...prev, topic]
      );
    } else {
      const topicData = topics.find((t) => t.topic === topic);
      const allSubtopicsSelected = topicData.subtopics.every((subtopic) =>
        selectedSubtopics.includes(subtopic)
      );

      if (allSubtopicsSelected) {
        setSelectedSubtopics((prev) =>
          prev.filter((subtopic) => !topicData.subtopics.includes(subtopic))
        );
      } else {
        setSelectedSubtopics((prev) => [
          ...prev,
          ...topicData.subtopics.filter((subtopic) => !prev.includes(subtopic)),
        ]);
      }
    }
  };
  const handleToggleSubtopic = (subtopic) => {
    setSelectedSubtopics(
      (prev) =>
        prev.includes(subtopic)
          ? prev.filter((s) => s !== subtopic) // Remove subtopic if already selected
          : [...prev, subtopic] // Add subtopic if not selected
    );
  };

  // Clear all selected subtopics
  const handleClearAll = () => {
    setSelectedSubtopics([]);
  };

  const handleAddTopicSubmit = () => {
    if (newTopic.trim()) {
      setSelectedSubtopics((prev) => [...prev, newTopic.trim()]);
      setNewTopic("");
      setIsAddTopicOpen(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleGenerateQuizClick = async () => {
    setIsLoading(true);
    await onStartQuizClick(selectedSubtopics, difficulty, numQuestions, quizMode);
    onClose();
    setIsLoading(false);
    try {
      setCreatedNewThread(createdNewThread + 1); // something wrong here, debug later. occassionally crashes
    } catch (error) {
      console.error(error);
    }
 
  };

  return (

    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "auto",
            maxHeight: "80vh",
            opacity:  1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >

            <Box
              sx={{
                position: 'absolute',
                top: 24,
                right: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'primary.main',
                color: 'white',
                padding: '2px 8px',
                borderRadius: 10,
                fontSize: 18,
                fontWeight: 400,
                border: '1px solid',
                borderColor: 'primary.main',
                gap: 0.5,
              }}
            >
              <NewReleasesIcon sx={{ fontSize: 18 }} />
              BETA
              </Box>

        
          {isLoading ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <CircularProgress size={50} thickness={4} />
              <Typography variant="h6">Generating your quiz...</Typography>
            </Box>
          ) : (
            
            
          <><Typography variant="h4" sx={{ fontWeight: 700 }}>
              Generate a Quiz for {course}
            </Typography>
              {/* <Card
                sx={{
                  p: 3,
                  boxShadow: 2,
                  borderRadius: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  flexGrow: 0,
                  overflow: "visible",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  Select Quiz Mode:
                </Typography>
                <RadioGroup
                  value={quizMode}
                  onChange={(e) => setQuizMode(e.target.value)}
                >
                  <FormControlLabel
                    value="practice"
                    control={<Radio />}
                    label="Practice Mode"
                  />
                  <Typography
                    sx={{
                      fontSize: 14,
                      ml: 4,
                      color: "text.secondary",
                    }}
                  >
                    - Immediate feedback for each answer.
                    <br />
                    - Hints available.
                    <br />- Not timed.
                  </Typography>
              
                  <FormControlLabel
                    value="challenge"
                    control={<Radio />}
                    label="Challenge Mode"
                  />
                  <Typography
                    sx={{
                      fontSize: 14,
                      ml: 4,
                      color: "text.secondary",
                    }}
                  >
                    - Feedback at the end of the quiz.
                    <br />
                    - No hints.
                    <br />- Timed quiz.
                  </Typography>
                </RadioGroup>
              </Card> */}
              <Card
                sx={{
                  p: 3,
                  boxShadow: 2,
                  borderRadius: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  flexGrow: 0,
                  overflow: "visible",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  How many questions?
                </Typography>
                <FormControl fullWidth>
                  <Slider
                    value={numQuestions}
                    onChange={(e, value) => setNumQuestions(value)}
                    step={1}
                    marks={[
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                      { value: 6, label: '6' },
                      { value: 7, label: '7' },
                      { value: 8, label: '8' },
                      { value: 9, label: '9' },
                      { value: 10, label: '10' },
                    ]}
                    min={1}
                    max={10}
                    valueLabelDisplay="auto" />
                </FormControl>
              </Card><Card
                sx={{
                  p: 2,
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  flexGrow: 1,
                  overflow: "visible",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  Select the topics you would like to be quizzed on
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {selectedSubtopics.map((subtopic) => (
                    <Button
                      key={subtopic}
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={() => handleToggleSubtopic(subtopic)}
                    >
                      {subtopic} ✕
                    </Button>
                  ))}
                </Box>
                {topics.map(({ topic, subtopics }) => (
                  <Box key={topic} sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControlLabel
                        control={<Checkbox
                          checked={(subtopics.length === 0
                            ? selectedSubtopics.includes(topic)
                            : true) &&
                            subtopics.every((sub) => selectedSubtopics.includes(sub)
                            )}
                          indeterminate={subtopics.some((sub) => selectedSubtopics.includes(sub)
                          ) &&
                            !subtopics.every((sub) => selectedSubtopics.includes(sub)
                            )}
                          onChange={() => handleToggleTopic(topic, subtopics.length > 0)} />}
                        label={topic} />
                      {subtopics.length > 0 && (
                        <IconButton
                          onClick={() => setExpandedTopic(expandedTopic === topic ? null : topic)}
                        >
                          {expandedTopic === topic ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <Collapse in={expandedTopic === topic}>
                      <Box
                        sx={{ pl: 4, display: "flex", flexWrap: "wrap", gap: 1 }}
                      >
                        {subtopics.map((subtopic) => (
                          <Button
                            key={subtopic}
                            variant="outlined"
                            size="small"
                            sx={{ textTransform: "none" }}
                            onClick={() => handleToggleSubtopic(subtopic)}
                          >
                            {subtopic} ✕
                          </Button>
                        ))}
                      </Box>
                    </Collapse>
                  </Box>
                ))}
                <Box
                  sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
                >
                  <Button variant="text" color="primary" onClick={handleClearAll}>
                    Clear All
                  </Button>
                  {/* <Button
      variant="outlined"
      color="primary"
      onClick={() => setIsAddTopicOpen(true)}
    >
      Add a Topic
    </Button> */}
                </Box>
              </Card><Card
                sx={{
                  p: 3,
                  boxShadow: 2,
                  borderRadius: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  flexGrow: 0,
                  overflow: "visible",
                  marginBottom: 3,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  Select Difficulty:
                </Typography>
                <RadioGroup
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  <FormControlLabel value="easy" control={<Radio />} label="Easy" />
                  <FormControlLabel
                    value="medium"
                    control={<Radio />}
                    label="Medium" />
                  <FormControlLabel value="hard" control={<Radio />} label="Hard" />
                </RadioGroup>
              </Card><Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                {isLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgcolor: "rgba(255, 255, 255, 0.7)",
                      zIndex: 10,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "uppercase",
                     bgcolor: "theme.palette.primary.main",
                     "&:hover": {
                      bgcolor: "theme.palette.primary.dark",
                    },
                  
                  }}
                  onClick={handleGenerateQuizClick}
                >
                  {isLoading ? "Generating..." : "Generate"}
                </Button>
              </Box></>
                 )} 
        </Box>
      </Modal>
      <Modal open={isAddTopicOpen} onClose={() => setIsAddTopicOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6">Add a New Topic</Typography>
          <TextField
            fullWidth
            label="Topic Name"
            value={newTopic}
            onChange={(e) => setNewTopic(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={() => setIsAddTopicOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleAddTopicSubmit}>
              Add
            </Button>
          </Box>


       
            
          
          


         
        </Box>
      </Modal>
    </>
    
  );
};
